<template>
    <div id="delete-instruction">
        <div class="u-container">
            <div class="u-row">
                <div class="u-col-12 main-content">
                    <h3 class="page-title">User Deletion Instruction</h3>
                    <p>This document provides detailed instruction on User Data deletion from the Up&Up system.</p>
                    <h5 class="section-title">Introduction</h5>
                    <p>The Up&Up system allows users to remove their profiles and all data connected to them, to provide customers a possibility to safeguard their data.</p>
                    <h5 class="section-title">User Deletion</h5>
                    <ol>
                        <li>To delete your profile and all personal data, please complete the following steps.</li>
                        <li>Login in to your Up&Up account.</li>
                        <li>Enter Personal profile.</li>
                        <li>Choose “Additional” in the right top corner.</li>
                        <li>Choose “User Deletion”.</li>
                        <li>Confirm User deletion in the pop-up window.</li>
                        <li>All data will be immediately removed from the database.</li>
                    </ol>
                    <p>You can create a new profile using your data.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-instruction',
        data () {
            return {}
        },
        created() {}
    }
</script>

<style lang="less" scoped>
    @import "../../../assets/styles/_vars.less";
    #delete-instruction {
        background-color: white;
        border-radius: 20px;
        padding: 60px;
        box-shadow: 0 0 5px 0 #949494;
        .u-container {
            .u-row {
                .main-content {
                    .page-title {
                        text-align: left;
                        margin-bottom: 30px;
                    }
                    .section-title {
                        text-align: left;
                    }
                    p {
                        text-align: left;
                        a {
                            color: @theme_color;
                            text-decoration: underline;
                        }
                    }
                    ol {
                        //padding-left: 18px;
                        li {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #delete-instruction {
            padding: 15px;
            .u-container {
                .u-row {
                    .main-content {
                        .page-title {
                            font-size: 1.5rem;
                            margin-bottom: 15px;
                        }
                        .section-title {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }

</style>

<!--<style lang="less" scoped>
    @import "../../../assets/styles/_vars.less";
    #delete-instruction {
        text-align: left;
        padding-bottom: 50px;
        p {

        }
        h2 {
            font-size: 26px;
        }
        h3 {
            font-size: 22px;
            margin-top: 30px;
        }
        ul {
            list-style-type: none;
            li {
                position: relative;
                &:before {
                    content: '-';
                    position: absolute;
                    left: -10px;
                }
            }
        }
        a {
            color: @theme_color;
            text-decoration: underline;
        }
    }
</style>-->

